<template>
  <div class="content-filter">
    <v-menu
      v-model="bMenu"
      bottom
      min-width="200px"
      :close-on-content-click="false"
      offset-y
      rounded="lg"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="btn-filter-global global-btn-neutral">
          <v-icon> mdi-tune-vertical </v-icon>
        </v-btn>
      </template>
      <v-card class="content-filter-menu">
        <v-container>
          <v-row>
            <!--FILTRO EXISTENCIA-->
            <v-col
              v-if="$route.name == 'RawMaterial'"
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >
              <p class="title-filter">Existencia</p>
              <div class="content-major-minor">
                <v-checkbox
                  v-for="(item, index) in itemExistence"
                  :key="index"
                  v-model="sExistence"
                  :value="item.value"
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                >
                  <template v-slot:label>
                    <span class="text-checkbox">{{ item.text }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <!-- FILTRO VARIACION -->
            <v-col
              v-if="$route.name == 'RawMaterial'"
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >
              <p class="title-filter">Variación</p>
              <div class="content-entries">
                <v-checkbox
                  v-for="(item, index) in itemVariation"
                  :key="index"
                  v-model="sVariation"
                  :value="item.value"
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                >
                  <template v-slot:label>
                    <span class="text-checkbox">{{ item.text }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <!--FILTRO ALFABETICAMENTE-->
            <v-col
              v-if="$route.name == 'Administrators'"
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >
              <p class="title-filter">Alfabéticamente</p>
              <div class="content-az">
                <v-checkbox
                  v-for="(item, index) in itemAlphabetic"
                  :key="index"
                  v-model="sAlphabetic"
                  :value="item.value"
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                >
                  <template v-slot:label>
                    <span class="text-checkbox">{{ item.text }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <!--FILTRO FECHA DE INGRESO-->
            <v-col
              v-if="$route.name == 'Administrators'"
              cols="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >
              <p class="title-filter">Fecha de ingreso</p>
              <div class="content-major-minor">
                <v-checkbox
                  v-for="(item, index) in itemDate"
                  :key="index"
                  v-model="sDate"
                  :value="item.value"
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                >
                  <template v-slot:label>
                    <span class="text-checkbox">{{ item.text }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <!-- TIPO DE DIVISA -->
            <v-col
              v-if="$route.name == 'Account'"
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4"
            >
              <p class="title-filter w-space">Tipo de divisa</p>
              <div class="content-major-minor">
                <v-checkbox
                  v-for="(item, index) in itemCurrency"
                  :key="index"
                  v-model="sCurrency"
                  :value="item.value"
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                >
                  <template v-slot:label>
                    <span class="text-checkbox w-space">{{ item.text }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
            <!-- BALANCE DE CUENTA -->
            <v-col
              v-if="$route.name == 'Account'"
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4"
            >
              <p class="title-filter ">Balance de cuenta</p>
              <div class="content-major-minor">
                <v-checkbox
                  v-for="(item, index) in itemAccountBalance"
                  :key="index"
                  v-model="sAccountBalance"
                  :value="item.value"
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                >
                  <template v-slot:label>
                    <span class="text-checkbox">{{ item.text }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <!-- TIPO DE CUENTA -->
            <v-col
              v-if="$route.name == 'Account'"
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4"
            >
              <p class="title-filter">Tipo de cuenta</p>
              <div class="content-major-minor">
                <v-checkbox
                  v-for="(item, index) in aItemTypeAccountGlobal"
                  :key="index"
                  v-model="sAccountType"
                  :value="item.sAccountTypeId"
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                >
                  <template v-slot:label>
                    <span class="text-checkbox">{{ item.sName }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <!-- TIPO DE OPERACIÓN -->
            <v-col
              v-if="
                $route.name == 'OPGeneral' ||
                $route.name == 'OPPending' ||
                $route.name == 'OPPayable'
              "
              cols="12"
              :sm="bGetItemStatusOrder ? '6' : '12'"
              :md="bGetItemStatusOrder ? '6' : '12'"
              :lg="bGetItemStatusOrder ? '6' : '12'"
              :xl="bGetItemStatusOrder ? '6' : '12'"
            >
              <p class="title-filter">Tipo de operación</p>
              <div class="content-major-minor">
                <v-checkbox
                  v-for="(item, index) in itemTypeOperation"
                  :key="index"
                  v-model="sTypeOperation"
                  :value="item.value"
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                >
                  <template v-slot:label>
                    <span class="text-checkbox">{{ item.text }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
            <!-- ESTADO O.C. -->
            <v-col
              v-if="bGetItemStatusOrder"
              cols="12"
              :sm="bGetItemStatusOrder ? '6' : '12'"
              :md="bGetItemStatusOrder ? '6' : '12'"
              :lg="bGetItemStatusOrder ? '6' : '12'"
              :xl="bGetItemStatusOrder ? '6' : '12'"
            >
              <p class="title-filter">Estado</p>
              <div class="content-major-minor">
                <v-checkbox
                  v-for="(item, index) in itemStatusOC"
                  :key="index"
                  v-model="aStatusOC"
                  :value="item.value"
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                >
                  <template v-slot:label>
                    <span class="text-checkbox">{{ item.text }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bMenu: false,
      sExistence: "",
      itemExistence: [
        {
          text: "Mayor a menor",
          value: "desc",
        },
        {
          text: "Menor a mayor",
          value: "asc",
        },
      ],

      sVariation: "",
      itemVariation: [
        {
          text: "Entradas",
          value: "asc",
        },
        {
          text: "Salidas",
          value: "desc",
        },
      ],

      sAlphabetic: "",
      itemAlphabetic: [
        {
          text: "De la A a la Z",
          value: "asc",
        },
        {
          text: "De la Z a la A",
          value: "desc",
        },
      ],

      sDate: "",
      itemDate: [
        {
          text: "Ascendente",
          value: "asc",
        },
        {
          text: "Descendente",
          value: "desc",
        },
      ],

      sCurrency: "",
      itemCurrency: [],

      sAccountBalance: "",
      itemAccountBalance: [
        {
          text: "Mayor a menor",
          value: "desc",
        },
        {
          text: "Menor a mayor",
          value: "asc",
        },
      ],

      sAccountType: "",
      itemAccountType: [],

      aStatusOC: [],
      itemStatusOC: [],
      sTypeOperation: "",
      itemTypeOperation: [
        {
          text: "Pedidos de venta",
          value: "VENTA",
        },
        {
          text: "Órdenes de compra",
          value: "COMPRA",
        },
        {
          text: "Báscula pública",
          value: "PUBLICA",
        },
        {
          text: "Todas",
          value: "TODAS",
        },
      ],
      aGetItemStatusOrder: ["OPGeneral", "OPPending"],
      aGetTypeAccount: ["Account"],
    };
  },
  beforeMount() {
    if (
      this.aGetItemStatusOrder.filter((e) => e === this.$route.name).length > 0
    ) {
      this.getListStatusOrder();
    }

    if (this.$route.name === "Account") {
      this.getTypeCurrency();
    }
    this.setFillFilterGlobal();
  },
  methods: {
    getTypeAccount() {
      if (this.bGetTypeAccount) {
        this.$store
          .dispatch("getItemTypeAccount")
          .then((resp) => {})
          .catch((err) => {
            this.Error(err);
          });
      }
    },
    getTypeCurrency() {
      if (this.bGetTypeAccount) {
        this.$store
          .dispatch("getItemTypeCurrencyGlobal")
          .then((resp) => {
            this.itemCurrency = resp.data.results.map((e) => {
              return {
                text: e.sCode,
                value: e.sCurrencyId,
              };
            });
          })
          .catch((err) => {
            this.Error(err);
          });
      }
    },
    getListStatusOrder() {
      this.$store
        .dispatch("getItemStatusOrderGlobal")
        .then((resp) => {
          switch (this.$route.name) {
            case "OPGeneral":
              this.itemStatusOC = resp.data.results;
              this.aStatusOC = this.aStatusGlobal;

              break;
            case "OPPending":
              this.itemStatusOC = resp.data.results.filter(
                (e) => e.value === "PESO INICIAL" || e.value === "PESO ASIGNADO"
              );
              this.aStatusOC = this.aStatusGlobal;

              break;

            default:
              break;
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    setFillFilterGlobal() {
      this.sTypeOperation = this.sTypeOperationGlobal;
    },
  },
  computed: {
    aItemStatusOrderGlobal() {
      return this.$store.state.aItemStatusOrderGlobal;
    },
    aItemTypeAccountGlobal() {
      return this.$store.state.aItemTypeAccountGlobal;
    },
    bGetItemStatusOrder() {
      return (
        this.aGetItemStatusOrder.filter((e) => e === this.$route.name).length >
        0
      );
    },
    bGetTypeAccount() {
      return (
        this.aGetTypeAccount.filter((e) => e === this.$route.name).length > 0
      );
    },
    aStatusGlobal() {
      return this.$store.state.aStatusGlobal;
    },
    sTypeOperationGlobal() {
      return this.$store.state.sTypeOperationGlobal;
    },
  },
  watch: {
    bMenu() {
      if (this.bMenu) {
        this.getListStatusOrder();
        this.getTypeAccount();
      }
    },
    sExistence() {
      this.$emit("setExistentMenu", this.sExistence);
    },
    sVariation() {
      this.$emit("setVariantMenu", this.sVariation);
    },
    sAlphabetic() {
      this.$emit("setSortNameMenu", this.sAlphabetic);
    },
    sDate() {
      this.$emit("setSortDateMenu", this.sDate);
    },
    sCurrency() {
      this.$emit("setCurrencyMenu", this.sCurrency);
    },
    sAccountBalance() {
      this.$emit("setAccountBalanceMenu", this.sAccountBalance);
    },
    sAccountType() {
      this.$emit("setAccountTypeMenu", this.sAccountType);
    },
    aStatusOC() {
      this.$store.commit("setStatusGlobal", this.aStatusOC);
      this.$emit("setStatusOCMenu", this.aStatusOC);
    },
    sTypeOperation() {
      this.$store.commit("setTypeOperationGlobal", this.sTypeOperation);
      this.$emit("setTypeOperationMenu", this.sTypeOperation);
    },
  },
};
</script>

<style scoped>
.content-search-raw-material {
  display: block;
  align-content: center;
  width: 100%;
}

.content-filters-Provider {
  display: flex;
  margin-top: 10px;
}

.content-filters-RawMaterial {
  display: flex;
  margin-top: 10px;
}

.content-filters-Administrators {
  display: flex;
  margin-top: 10px;
}

.content-filters-Account {
  display: flex;
  margin-top: 10px;
}

.content-filters-OPGeneral {
  display: flex;
  margin-top: 0px;
  padding: 10px 10px 0px 0px !important;
}

.content-filters-OPPending {
  display: flex;
  margin-top: 0px;
  padding: 10px 10px 0px 0px !important;
}

.content-filters-OPPayable {
  display: flex;
  margin-top: 0px;
  padding: 10px 10px 0px 10px !important;
}

.content-filter-text-field {
  min-width: 390px;
  margin-bottom: 10px;
}

.content-filter-text-field-OPGeneral {
  width: 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  margin-bottom: 10px;
}

.content-filter-select-institutions {
  /* width: 60%; */
  margin-left: 15px;
  min-width: 390px;
  margin-bottom: 10px;
}

.content-filter-add-raw-material {
  display: flex;
  justify-content: end;
  align-content: center;
  width: 100%;
}

.content-text-title {
  width: 100%;
  margin-bottom: 10px;
}

.text-title {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 2rem;
  margin-bottom: 0px;
}

.title-filter {
  font-size: 14px;
  font-family: "pop-Bold";
}

.text-checkbox {
  font-size: 14px;
  font-family: "pop-Light";
}

.content-filter-date-range {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  margin-left: 15px;
}

.content-filter-menu {
  background-color: var(--primary-color-menu-filter) !important;
  width: auto;
  min-width: 245px;
  max-width: 550px;
  overflow: hidden;
  border-radius: 10px !important;
  border: var(--primary-color-border-menu-profile) solid 1px;
}

.btn-filter-global {
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-search-raw-material {
    display: block;
  }

  .content-filter-add-raw-material {
    display: block;
  }

  .content-filter {
    margin-bottom: 15px;
  }

  .content-filters {
    display: block;
  }

  .content-filters-Provider {
    display: block;
  }

  .content-filters-RawMaterial {
    display: block;
  }

  .content-filters-Administrators {
    display: block;
  }

  .content-filters-Account {
    display: block;
  }

  .content-filters-OPGeneral {
    display: block;
    margin-top: 0px;
    padding: 0px 0px 0px 0px !important;
  }

  .content-filters-OPPending {
    display: block;
    margin-top: 0px;
    padding: 0px 0px 0px 0px !important;
  }

  .content-filter-select-institutions {
    /* width: 60%; */
    margin-left: 0px;
    min-width: 100%;
    margin-bottom: 10px;
  }

  .content-filter-text-field {
    min-width: 100%;
  }

  .content-filter-text-field-OPGeneral {
    min-width: 100% !important;
    margin-bottom: 10px;
  }

  .content-filter-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .content-filters-Account {
    display: flex;
  }

  .content-filter-text-field {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-select-institutions {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-text-field-OPGeneral {
    min-width: 180px !important;
    margin-bottom: 10px;
  }

  .content-filter-date-range {
    width: 266px;
    max-width: 266px;
    min-width: 266px;
    margin-left: 15px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-filter-text-field {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-select-institutions {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-text-field-OPGeneral {
    /* width: 18% !important; */
    min-width: 180px !important;
    margin-bottom: 10px;
  }

  .content-filter-date-range {
    width: 270px !important;
    max-width: 270px !important;
    min-width: 270px !important;
    margin-left: 15px;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
